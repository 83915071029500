export { default as AmplifyClipArt } from '/public/images/clipart/amplify.svg';
export { default as ChartNotFoundClipArt } from '/public/images/clipart/chart_not_found.svg';
export { default as ContentGenerationClipArt } from '/public/images/clipart/content_generation.svg';
export { default as ContextLibraryClipArt } from '/public/images/clipart/context_library.svg';
export { default as EmptyInboxClipArt } from '/public/images/clipart/empty_inbox.svg';
export { default as GlossaryClipArt } from '/public/images/clipart/glossary.svg';
export { default as GlossaryCreateClipArt } from '/public/images/clipart/glossary_create.svg';
export { default as GlossaryDeleteClipArt } from '/public/images/clipart/glossary_delete.svg';
export { default as LogoBlueClipArt } from '/public/images/clipart/logo-blue.svg';
export { default as LogoGreenClipArt } from '/public/images/clipart/logo-green.svg';
export { default as LogoWhiteClipArt } from '/public/images/clipart/logo-white.svg';
export { default as LogoClipArt } from '/public/images/clipart/logo.svg';
export { default as MaintenanceClipArt } from '/public/images/clipart/maintenance.svg';
export { default as NewEmailClipArt } from '/public/images/clipart/new_email.svg';
export { default as NewPasswordCreatedClipArt } from '/public/images/clipart/new_password_created.svg';
export { default as NotificationsNotFoundClipArt } from '/public/images/clipart/notifications_not_found.svg';
export { default as OrderProjectClipArt } from '/public/images/clipart/order_project.svg';
export { default as ProductivityClipArt } from '/public/images/clipart/productivity.svg';
export { default as RecentActivitiesNotFoundClipArt } from '/public/images/clipart/recent_activities_not_found.svg';
export { default as SaasTranslatorClipArt } from '/public/images/clipart/saas-translator.svg';
export { default as SecurityClipArt } from '/public/images/clipart/security.svg';
export { default as SecurityDisableClipArt } from '/public/images/clipart/security_disable.svg';
export { default as SelectGoalsClipArt } from '/public/images/clipart/select_goals.svg';
export { default as StyleGuidesClipArt } from '/public/images/clipart/style_guides.svg';
export { default as StyleGuidesCreateClipArt } from '/public/images/clipart/style_guides_create.svg';
export { default as StyleGuidesDeleteClipArt } from '/public/images/clipart/style_guides_delete.svg';
export { default as StyleGuidesIconClipArt } from '/public/images/clipart/style_guides_icon.svg';
export { default as TranslationMemoryClipArt } from '/public/images/clipart/translation_memory.svg';
export { default as TranslationMemoryDeleteClipArt } from '/public/images/clipart/translation_memory_delete.svg';
export { default as TwoFactorClipArt } from '/public/images/clipart/two_factor.svg';
export { default as UploadDocumentsClipArt } from '/public/images/clipart/upload_documents.svg';
export { default as UserClipArt } from '/public/images/clipart/user.svg';
